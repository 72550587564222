import useIsAnyFocused from './useIsAnyFocused';
import { useEffect, useState } from 'react';

const CSS_DISABLE_MOUSE = 'disable-mouse';

enum KeyboardKeys {
  BACKSPACE = 8,
  DELETE = 46,
  SHIFT = 16,
  CONTROL = 17,
  OPTION = 18,
  COMMAND = 91,
}

const MODIFIER_KEYS = [KeyboardKeys.SHIFT, KeyboardKeys.CONTROL, KeyboardKeys.OPTION, KeyboardKeys.COMMAND];

export default function useKeyboardCapture({
  onEscape,
  onDown,
  onTab,
  onUp,
  onEnter,
  onBackspace,
  onRight,
  onDelete,
  onLeft,
  enabled,
  preventDefault,
}: {
  enabled?: boolean;
  onEscape?: () => void | boolean;
  onDown?: () => void | boolean;
  onUp?: () => void | boolean;
  onTab?: () => void | boolean;
  onLeft?: () => void | boolean;
  onRight?: () => void | boolean;
  onEnter?: () => void | boolean;
  onDelete?: () => void | boolean;
  onBackspace?: () => void | boolean;
  preventDefault?: boolean;
}) {
  const [modifier, setModifier] = useState(false);
  const focused = useIsAnyFocused();

  const mapping = {
    39: onRight,
    37: onLeft,
    9: onTab,
    27: onEscape,
    [KeyboardKeys.BACKSPACE]: onBackspace,
    [KeyboardKeys.DELETE]: onDelete,
    40: onDown,
    38: onUp,
    13: onEnter,
  };

  const modifierListener = (e: KeyboardEvent) => {
    if (MODIFIER_KEYS.includes(e.keyCode)) {
      setModifier(false);
    }
  };

  const isEnabled = () => (typeof enabled !== 'undefined' ? enabled : !focused);

  const keyListener = (e: KeyboardEvent) => {
    if (!isEnabled() || modifier) {
      return;
    }

    if (MODIFIER_KEYS.includes(e.keyCode)) {
      setModifier(true);
    }

    for (const [keyCode, fn] of Object.entries(mapping)) {
      if (e.keyCode === parseInt(keyCode) && fn) {
        if (!document.body.className.includes(CSS_DISABLE_MOUSE)) {
          document.body.className += ` ${CSS_DISABLE_MOUSE}`;
        }

        const prevent = fn();

        if (typeof prevent === 'boolean' ? prevent : preventDefault) {
          e.stopPropagation();
          e.preventDefault();
        }
      }
    }
  };

  useEffect(() => {
    const trackMouse = () => {
      document.body.className = document.body.className.replace(CSS_DISABLE_MOUSE, '');
    };

    window.addEventListener('keydown', keyListener, { capture: true });
    window.addEventListener('keyup', modifierListener);
    window.addEventListener('mousemove', trackMouse);

    return () => {
      window.removeEventListener('keydown', keyListener, { capture: true });
      window.removeEventListener('keyup', modifierListener);
      window.removeEventListener('mousemove', trackMouse);
    };
  });
}
